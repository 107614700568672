
.fa:hover {
    opacity: 0.7;
}
.fa-youtube {
  margin-left: 10.5px;
}
.fa-instagram {
  margin-right: 10.5px;
  width:50%;
}

.fa-youtubem {
  margin-left: 10.5px;
}
.fa-instagramm {
  margin-right: 10.5px;
  width:50%;
}

.nodrag{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.links{
  padding-top: 40px;
  text-align: center; 
  user-select: none;
  padding-bottom: 40px;
}

.fa-instagram:hover {
  transform: scale(1.05);
}

.fa-youtube:hover {
  transform: scale(1.05);
}

@media screen and (max-width:950px){
  .fa-youtube{
    display:none !important;
  }
  .fa-instagram{
    display:none !important;
  }
}

@media screen and (min-width:950px){
  .fa-youtubem{
    display:none !important;
  }
  .fa-instagramm{
    display:none !important;
  }
}