.nodrag{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.container-div{ 
  display: flex;
  height: 100vh;
  width: 100vw; 
  align-items: center;
  justify-content: center;
  }

@media screen and (max-width:1100px){
  .desk1{
    display:none !important; 
  }
}

@media screen and (min-width:1100px){
  .mobile1{
    display:none !important; 
  }
}