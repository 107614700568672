* {
  font-family: 'IBM Plex Sans' !important;
  text-decoration: none;
}

.navbar-logo {
  text-decoration: none;
  position: absolute;
  margin-top: 20px;
  padding-bottom: 10px;
}

.navbar-gallery {
  color: #ffffff !important;
  font-size: medium !important;
  font-weight: normal !important;
  text-transform: none !important;
  border-radius: 5px !important;
  left: 20%;
  position: absolute !important;
  margin-top: 5px !important; 
  padding-bottom: 13px !important; 
}

.navbar-shop {
  color: #ffffff !important;
  font-size: medium !important;
  font-weight: normal !important;
  text-transform: none !important;
  border-radius: 5px !important;
  left: 30%;
  position: absolute !important;
  margin-top: 5px !important; 
  padding-bottom: 13px !important; 
}

.navbar-meets {
  color: #ffffff !important;
  font-size: medium !important;
  font-weight: normal !important;
  text-transform: none !important;
  border-radius: 5px !important;
  left: 40%;
  position: absolute !important;
  margin-top: 5px !important; 
  padding-bottom: 13px !important; 
  
}

.navbar-hamburg-logo {
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  margin-top: 20px;
  padding-bottom: 20px;
  left: 85%;
}

.hamburg-grid {
  padding-bottom: 20px;
  width: 100%;
}

.nav-link-open-container {
  padding-top: 5px;
  height: 40px;
  border-bottom: 1px solid var(--navbar-open-bg-line);
}

.hamburg-item {
  color: var(--primary-text);
  text-decoration: none;
  font-weight: 500;
  font-size: 26px;
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 5px;
  padding-bottom: 3px;
  padding-right: 115px;
  width: 100%;
  list-style: none;
  display: flex;
}

/* .navbar-sign-in  {
  color: #2796FD !important;
  position: absolute !important;
  right: 20%;
  transition: transform .2s !important;
} */

.navbar-meets:hover {
  transform: scale(1.05);
}

.navbar-shop:hover {
  transform: scale(1.05);
}

.navbar-gallery:hover {
  transform: scale(1.05);
}

.navbar-logo:hover {
  transform: scale(1.05);
}

@media screen and (max-width:1450px){
  .navbar-gallery{
    left: 25%!important; 
  }
  .navbar-shop{
    left: 45%!important;
  }
  .navbar-meets{
    left: 65%!important;
  }
}

@media screen and (max-width:1225px){
  .navbar-gallery{
    left: 30%!important; 
  }
  .navbar-shop{
    left: 50%!important;
  }
  .navbar-meets{
    left: 70%!important;
  }
}

@media screen and (max-width:1000px){
  .navbar-gallery{
    left: 35%!important; 
  }
  .navbar-shop{
    left: 55%!important;
  }
  .navbar-meets{
    left: 75%!important;
  }
}

@media screen and (max-width:900px){
  .navbar-gallery{
    left: 37.5%!important; 
  }
  .navbar-shop{
    left: 57.5%!important;
  }
  .navbar-meets{
    left: 77.5%!important;
  }
}

@media screen and (max-width:750px){
  .navbar-gallery{
    display:none !important; 
  }
  .navbar-shop{
    display:none !important; 
  }
  .navbar-meets{
    display:none !important; 
  }
}

@media screen and (min-width:750px){
  .container{
    display:none !important; 
  }
  .navbar-hamburg-logo{
    display:none !important; 
  }
  .navbar-hamburg {
    display: none !important;
  }
  .hamburg-grid {
    display: none !important;
  }
}

.left{
  padding-left: 95%!important;
}
.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: rgb(72, 74, 75);
  margin: 6px 0;
  transition: 0.4s;
  left: 60%!important;
  border-radius: 25px;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.container:hover {
  transform: scale(1.05);
}

.phone-menu{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 60%!important;
  text-align: center;
  text-decoration: none!important;
  color: rgb(255, 0, 0);
  font-size: larger;
  
}

.nodrag{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}