.hamburger-icon {
  cursor: pointer;
  width: 40px; /* Adjust as needed */
  height: 35px; /* Adjust as needed */
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: none;
  margin-left: auto;
  margin-right: 30px;
}

.hamburger-line {
  width: 100%;
  height: 4px;
  background-color: rgb(255, 255, 255); /* Adjust color as needed */
  transition: all 0.3s ease;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.8);
}

.hamburger-icon.open .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(7px, 8px);
  box-shadow: none;
}

.hamburger-icon.open .hamburger-line:nth-child(2) {
  opacity: 0;
  box-shadow: none;
}

.hamburger-icon.open .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -10px);
  box-shadow: none;
}


@media (max-width: 650px){
  .hamburger-icon {
    display: flex;
  }
}