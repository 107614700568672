@media screen and (max-width:1100px){
  .logandesk{
    display:none !important; 
  }
  .zacdesk{
    display:none !important; 
  }
  .danieldesk{
    display:none !important; 
  }
}

@media screen and (min-width:1100px){
  .loganmobile{
    display:none !important; 
  }
  .zacmobile{
    display:none !important; 
  }
  .danielmobile{
    display:none !important; 
  }
}